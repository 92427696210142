import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import {
	FaCalendarAlt,
	FaSearchDollar,
	FaBug,
	FaSortAmountDown,
	FaRobot,
	FaSyncAlt
} from "react-icons/fa";

// Local imports
import Layout from "src/layouts/LayoutWhiteHeader";
import StripPrimary from "src/components/generic/StripPrimary/StripPrimary";
import StripTitle from "src/components/generic/StripTitle/StripTitle";
import StripsAlternatingContent from "src/components/generic/StripsAlternatingContent/StripsAlternatingContent";
import SEO from "src/components/generic/SEO/SEO";
import config from "data/SiteConfig";

// Import videos
import StripTwoColumnIconSmall from "src/components/generic/StripTwoColumnIconSmall/StripTwoColumnIconSmall";
import OutboundLink from "src/components/mdx/OutboundLink/OutboundLink";

// Styles
// import styles from "./index.module.scss";

const stripPrimary = {
	title: "Template Checker Report",
	subTitle: (
		<>
			<p>
				Track all of your SEO technical checks by template. It&apos;s
				like having your own unit tests.
			</p>
			<OutboundLink
				href="https://lookerstudio.google.com/reporting/112a6887-1b38-41f7-ab08-c1faa1bd8030/"
				className={`button buttonSecondary`}
			>
				Explore anonymised report
			</OutboundLink>
		</>
	)
};

const alternatingContent = [
	{
		subTitle: "Video Walkthrough",
		content: (
			<React.Fragment>
				<p>Sometimes it&apos;s easier to watch a video.</p>
				<p>
					If you&apos;d like a short walkthrough of what this report
					can do we&apos;ve got one here.
				</p>
			</React.Fragment>
		),
		secondContent: (
			<>
				<div className={`videoHolder`}>
					<iframe
						src="https://player.vimeo.com/video/909205554?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
						frameBorder="0"
						allow="autoplay; fullscreen; picture-in-picture"
						allowfullscreen
						title="piped out intro 3"
					></iframe>
				</div>
				<script src="https://player.vimeo.com/api/player.js%22%3E" />
			</>
		)
	}
];

const dashboardStrengths = [
	{
		title: "Monitor all of your important templates",
		content: (
			<React.Fragment>
				<p>
					Websites are built on templates. You don&apos;t actually
					have 5,000 missing canonical tags. You have one missing
					canonical a page template that&apos;s used 5,000 times.
				</p>
				<p>
					By checking a sample of pages we&apos;ll find the errors
					quickly and avoid barraging you with data.
				</p>
			</React.Fragment>
		),
		icon: <FaSearchDollar />
	},
	{
		title: "Find errors which won't normally be reported as changes",
		content: (
			<React.Fragment>
				<p>
					A template which is no-indexed isn&apos;t an error. But you
					still might want that template indexed.
				</p>
				<p>
					We let you set the correct value for each check on each
					template then we&apos;ll tell you if the value is correct.
				</p>
			</React.Fragment>
		),
		icon: <FaBug />
	},
	{
		title: "Priotise the templates",
		content: (
			<React.Fragment>
				<p>
					We can join the crawl data with your analytics so you can
					see things like &quot;average organic sessions&quot; per
					template.
				</p>
				<p>
					This provides a little more information to help you
					prioritise issues when you do spot them on the dashboard.
				</p>
			</React.Fragment>
		),
		icon: <FaSortAmountDown />
	},
	{
		title: "Hide issues while waiting for fixes to be deployed",
		content: (
			<React.Fragment>
				<p>
					We let ignore issues on templates that do exist, so you can
					get rid of the errors you know about while waiting for
					development fixes.
				</p>
			</React.Fragment>
		),
		icon: <FaRobot />
	},

	{
		title: "Crawl every day",
		content: (
			<React.Fragment>
				<p>
					On a really large website, it&apos;s expensive to crawl your
					entire site every single day. But you can crawl a sample of
					pages every day and still discover all the issues on you
					different page templates.
				</p>
			</React.Fragment>
		),
		icon: <FaCalendarAlt />
	},
	{
		title: "Automatic updates",
		content: (
			<React.Fragment>
				<p>
					Like all our dashboards the data is updated automatically
					every day.
				</p>
			</React.Fragment>
		),
		icon: <FaSyncAlt />
	}
];

const propTypes = {
	data: PropTypes.shape().isRequired,
	location: PropTypes.shape().isRequired
};

const Index = ({ data, location }) => {
	// Set images

	const title = "Template Monitor Dashboard";
	const description =
		"By crawling a small sample of URLs we can help you keep track of all the different technical issues you might have with minimal work.";

	return (
		<Layout>
			<Helmet title={config.siteTitle} />
			<SEO pageTitle={title} pageDescription={description} />
			<StripPrimary
				title={stripPrimary.title}
				subTitle={stripPrimary.subTitle}
				fullSized={false}
				isWhite={true}
			/>

			<section>
				{/* <StripTitle title="How does analysing data work?" /> */}
				<StripsAlternatingContent
					stripList={alternatingContent}
					classes="m-t-lg"
					noBotMargin={true}
				/>
			</section>
			<StripTitle title="Why use our dashboard?" />
			<StripTwoColumnIconSmall
				contentList={dashboardStrengths}
				backgroundGrey={false}
			/>
		</Layout>
	);
};

Index.propTypes = propTypes;

export default Index;

// The graphql query made here will automatically be entered as the
// prop data in data
export const pageQuery = graphql`
	query TemplateCheckerData {
		# Get us the main alternating images
		alternatingImg1: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/save_query_results.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 681, maxHeight: 632) {
					...GatsbyImageSharpFluid
				}
			}
		}
		alternatingImg2: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/query_builder.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 916, maxHeight: 705) {
					...GatsbyImageSharpFluid
				}
			}
		}
		alternatingImg3: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/business_logic.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 1051, maxHeight: 866) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
